//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DocumentsManager from "@/components/DocumentsManager.vue";

export default {
    components: {
        DocumentsManager
    },

    data() {
        return {
            doc1_loading: true,
            doc1_url: null
        };
    },

    computed: {
        app() {
            return this.$store.getters["application/getApplication"];
        }
    },

    methods: {
        downloadApplication() {
            console.log("Pobieranie wniosku...");
        }
    },

    async mounted() {
        if (!this.$store.state.initial_layout_set) return;
        await this.$store.dispatch("application/awaitForApplication");

        // pobieranie doc1
        try {
            const r = await this.$axios.$get(`/files/${this.app.application_draft}/signed-url`);

            this.doc1_url = r.url;
            this.doc1_loading = false;
        } catch (err) {
            console.error(err);
        }
    },

    metaInfo: {
        title: "Twój wniosek"
    }
};
