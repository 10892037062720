//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    metaInfo: {
        title: "Wniosek w przygotowaniu..."
    }
};
